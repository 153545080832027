<template>
  <button :disabled="disabled" :size="size" :type="type" :class="[size]">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "VButton",

  props: {
    type: {
      type: String,
      default: 'button'
    },
    size: {
      type: String,
      default: 'medium'
    },
    disabled: Boolean,
    loading: Boolean
  },
}
</script>

<style lang="scss" scoped>
button {
  width: auto;
  min-width: 186px;
  height: auto;
  padding: 11px 38px;
  font-size: 15px;
  line-height: 20px;
  border-radius: 4px;
  color: #FFFFFF;
  background-color: #0059A3;
  transition: all .2s ease-in-out;

  &:disabled {
    background-color: #F1F4F8;
    color: #B1BACB;
    pointer-events: none;
  }

  &:hover {
    background-color: #046dc6;
  }

  &.large {
    padding: 15px 70px;
  }

  &.gray {
    background-color: #F1F4F8;
    color: #B1BACB;
  }
}
</style>
