<template>
  <Review :analysis="form" class="wrapper-preview"/>
</template>

<script>
import Review from "@/views/analysis/update/components/Review";
import {getById} from "@/api/analysis/getById";
import _ from "lodash";

export default {
  components: {Review},
  name: "AnalysisView",
  data() {
    return {
      form: {
        lang: null,
        type: null,
        pcr: null,
        antibodyType: null,
        antibodyTypeG: null,
        antibodyTypeM: null,
        antibodyTypeGS: null,
        order_date: null,
        issue_date: null,

        surname_ru: null,
        name_ru: null,
        patronymic_ru: null,
        address_ru: null,
        email: null,

        surname_en: null,
        name_en: null,
        patronymic_en: null,
        address_en: null,

        gender: 'male',
        birthdate: null,
        phone: null,

        snils: null,
        doc: null,
        series: null,
        number: null,
        gosuslugi: false,

        pcr_doctor_id: null,
        pcr_cert_doctor_id: null,
        antibody_doctor_id: null,
        step: null,
      },
    }
  },
  created() {
    this.getAnalysis(this.$route.params.id);
  },
  methods: {
    getAnalysis(id) {
      getById(id).then((res) => {
        let data = res.data.data

        //данные для 1 шага
        this.form.id = data.id
        this.form.type = data.type.value
        this.form.lang = data.lang.value

        _.map(data.doctors, val => { //проверить
          if (val.type === 'pcr') {
            this.form.pcr_doctor_id = val.id
          }
          if (val.type === 'pcr_cert') {
            this.form.pcr_cert_doctor_id = val.id
          }
          if (val.type === 'antibody') {
            this.form.antibody_doctor_id = val.id
          }
        })

        //данные для 2 шага
        this.form.phone = data.client.phone
        this.form.email = data.client.email
        this.form.gender = data.client.gender.value

        this.form.name_ru = data.client.attributes.ru.name
        this.form.surname_ru = data.client.attributes.ru.surname
        this.form.patronymic_ru = data.client.attributes.ru.patronymic
        this.form.address_ru = data.client.attributes.ru.address

        this.form.client_id = data.client.id
        this.form.name_en = data.client.attributes.en.name
        this.form.surname_en = data.client.attributes.en.surname
        this.form.patronymic_en = data.client.attributes.en.patronymic
        this.form.address_en = data.client.attributes.en.address

        this.form.order_date = data.order_date
        this.form.issue_date = data.issue_date
        this.form.birthdate = data.client.birthdate
        this.form.doc = data.doc
        this.form.doc_en = data.doc_en

        this.form.pcr = data.pcr
        this.form.antibodyTypeG = data.antibody_g
        this.form.antibodyTypeM = data.antibody_m
        this.form.antibodyTypeGS = data.antibody_gs

        if (data.antibody_g && data.antibody_m) {
          this.form.antibodyType = 'g_m'
        } else if (data.antibody_g) {
          this.form.antibodyType = 'g'
        } else if (data.antibody_m) {
          this.form.antibodyType = 'm'
        } else if (data.antibody_gs) {
          this.form.antibodyType = 'gs'
        }
  
        _.map(data.client.documents, val => {
          switch (val.type.value) {
            case 'snils':
              this.form.snils = val.number;
              break;
            case this.form.doc:
              this.form.series = val.series
              this.form.number = val.number
              // falls through
            case this.form.doc_en:
              this.form.series_en = val.series
              this.form.number_en = val.number
              break;
          }
        })

        this.currentStep = 1
      })
    },
  },
}
</script>

<style lang="scss">
.wrapper-preview .preview {
  max-height: unset !important;
  padding: 0 !important;
  overflow: hidden !important;
}
</style>