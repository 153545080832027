<template>
  <div :style="`justify-content: ${justify}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    justify: {
      type: String,
      default: 'space-between'
    }
  }
}
</script>

<style lang="scss" scoped>
div {
  color: #B1BACB;
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
  display: flex;
}
</style>