<template>
  <div>
    <Title v-if="!hideBack">
      Просмотр анализа
      <router-link to="/analysis">
        <v-button>Назад</v-button>
      </router-link>
    </Title>
    <div class="preview">
      <table>
        <tbody>
        <tr>
          <td>ФИО / Full name:</td>
          <td>
            <span v-if="full_name_ru">{{ full_name_ru }}</span>
            <span v-if="full_name_ru && full_name_en"> / </span>
            <span v-if="full_name_en">{{ full_name_en }}</span>
          </td>
        </tr>
        <tr>
          <td>Дата рождения / Date of birth:</td>
          <td>{{ analysis.birthdate | date }}</td>
        </tr>
        <tr>
          <td>Пол / Gender</td>
          <td>{{ analysis.gender | gender }}</td>
        </tr>
        <tr>
          <td>Адрес, страна / Address, country</td>
          <td>
            <span v-if="analysis.address_ru">{{ analysis.address_ru }}</span>
            <span v-if="analysis.address_ru && analysis.address_en"> / </span>
            <span v-if="analysis.address_en">{{ analysis.address_en }}</span>
          </td>
        </tr>
        <tr>
          <td>Тип документа / Document type</td>
          <td>
            <span v-if="analysis.doc">{{ analysis.doc | documentName }}</span>
            <span v-if="analysis.doc && analysis.doc_en"> / </span>
            <span v-if="analysis.doc_en">{{ analysis.doc_en | documentName }}</span>
          </td>
        </tr>
        <tr>
          <td>Номер документа / Document number</td>
          <td>
            <span v-if="analysis.number">{{ analysis.series }} {{ analysis.number }}</span>
            <span v-if="analysis.number && analysis.number_en"> / </span>
            <span v-if="analysis.number_en">{{ analysis.series_en }} {{ analysis.number_en }}</span>
          </td>
        </tr>
        <tr v-if="analysis.snils">
          <td>СНИЛС / Medical insurance</td>
          <td>{{ analysis.snils | snils }}</td>
        </tr>
        <tr>
          <td>Дата заказа / Order date</td>
          <td>{{ analysis.order_date | datetime }}</td>
        </tr>
        <tr v-if="analysis.issue_date">
          <td>Дата выдачи / Date of receiving</td>
          <td>{{ analysis.issue_date | datetime }}</td>
        </tr>

        <tr v-if="analysis.type !== 'antibody'">
          <td>Результат ПЦР / PCR result
          </td>
          <td>{{ analysis.pcr | pcrResult }}</td>
        </tr>

        <tr v-if="analysis.antibodyType === 'g' || analysis.antibodyType === 'g_m'">
          <td>
            Выявление иммуногло-булинов класса G к коронавирусу SARS- COV2 (IgG) в крови ИФА /
            Detection of class G immunoglobulins to SARS-COV2 coronavirus (IgG) in blood by
          </td>
          <td>{{ analysis.antibodyTypeG }}</td>
        </tr>
        <tr v-if="analysis.antibodyType === 'gs'">
          <td>
            Выявление иммуногло-булинов класса G к коронавирусу SARS- COV2 (IgG) в крови ИФА /
            Detection of class G immunoglobulins to SARS-COV2 coronavirus (IgG) in blood by
          </td>
          <td>{{ analysis.antibodyTypeGS }}</td>
        </tr>
        <tr v-if="analysis.antibodyType === 'm' || analysis.antibodyType === 'g_m'">
          <td>
            Выявление иммуногло-булинов класса М к коронавирусу SARS-COV2 (IgМ) в крови ИФА /
            Detection of class M immunoglobulins to SARS-COV2 coronavirus (IgM) in blood by
          </td>
          <td>{{ analysis.antibodyTypeM }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Title from '@/components/ui/Title'
import VButton from "@/components/ui/VButton";

export default {
  components: {Title, VButton},
  name: "Review",
  props: {
    analysis: {
      type: Object,
      required: true,
    },
    hideBack: Boolean
  },
  computed: {
    full_name_ru() {
      return this.getFullFame('ru');
    },
    full_name_en() {
      return this.getFullFame('en');
    },
  },
  methods: {
    getFullFame(lang) {
      const items = ['surname', 'name', 'patronymic'];

      let result = '';

      for (const item of items) {
        if (this.analysis[item + '_' + lang]) {
          result += this.analysis[item + '_' + lang] + ' ';
        }
      }

      return result;
    },
  },
}
</script>

<style lang="scss" scoped>
.preview {
  margin-bottom: 30px;

  max-height: 450px;
  overflow-y: scroll;
  padding-right: 10px;

  /* width */
  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #0059A3;
    border-radius: 3px;
  }

  table {
    width: 100%;
    background-color: white;
  }

  table, td {
    border: 1px solid #D5D9E1;
  }

  td {
    padding: 10px;

    &:nth-child(1) {
      width: 50%;
    }
  }
}
</style>